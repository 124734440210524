import './App.css';

import React, { useEffect, useState, useCallback, useRef } from 'react';
import WeatherMap from './WeatherMap';
import { FaMapMarkerAlt, FaMap, FaListAlt, FaCog } from 'react-icons/fa';
import { getAlert, getAlertText, getTemperatureColor, getUVColor, convertWindSpeed, convertTemperature, getDirectionFromAngle, getDirectionFromString, getWindDescription, getLongName, WeatherDescription, getWeatherDescription } from './WeatherUtils';
import { Helmet } from 'react-helmet';
import { useSwipeable } from 'react-swipeable';

const App = () => {
  const [weather, setWeather] = useState(null); // WeatherData
  const [location, setLocation] = useState(null); // GeolocationData
  const [themeMod, setThemeMod] = useState('white'); // Default theme
  const [notificationStatus, setNotificationStatus] = useState(null);
  const [geolocationConsent, setGeolocationConsent] = useState(null);
  const [tempUnits, setTempUnits] = useState('C'); // Default temperature unit
  const [windSpeedUnits, setWindSpeedUnits] = useState('m/s'); // Default wind speed unit
  const [userLang, setUserLang] = useState('en'); // Default language
  const [isLoading, setIsLoading] = useState(false); // Loader status
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isMapOpen1, setIsMapOpen1] = useState(false);
  
  const [notificationCityName, setNotificationCityName] = useState(localStorage.getItem('NotificationCityName') || 'No city selected');
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipAlert, setShowTooltipAlert] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  
  const [searchTerm, setSearchTerm] = useState(''); // Search term for city search
  const [searchResults, setSearchResults] = useState([]); // Search results for city search
  
  const [dateTime, setDateTime] = useState(''); // State to hold date/time
  const [currentHour, setCurrentHour] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [forecastForCurrentTime, setForecastForCurrentTime] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  
  const [cityList, setCityList] = useState(JSON.parse(localStorage.getItem('cityList')) || []);
  const tooltipRef = useRef(null);
  const [isMyLocationID, setIsMyLocationID] = useState(localStorage.getItem('isMyLocationID') || null);
  
  const dateRefs = useRef({}); // Store refs for each date
  const [movieUrl, setMovieUrl] = useState('');
  const [error, setError] = useState(null);

  const handleSwipe = (direction) => {
    const dates = Object.keys(weather.cityData.daily);
    const currentIndex = dates.indexOf(activeDate);

    if (direction === 'left' && currentIndex < dates.length - 1) {
      const newDate = dates[currentIndex + 1];
      handleDateClick(newDate);
      dateRefs.current[newDate]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    } else if (direction === 'right' && currentIndex > 0) {
      const newDate = dates[currentIndex - 1];
      handleDateClick(newDate);
      dateRefs.current[newDate]?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
  });
  
  // Function to handle city selection (in App.js)
  const onCitySelect = async (city) => {
    try {
      // Call the new API to get city details (timezone, lat, lon)
      const response = await fetch(`/api/cityDetails?cityId=${city.id}`);
      const cityDetails = await response.json();

      if (cityDetails && cityDetails.length > 0) {
        const { timezone, latitude, longitude } = cityDetails[0];

        // Set location details for weather forecast
        const locationData = {
          cityID: city.id,
          name: city.cn,
          countrycode: city.cc,
          latitude,
          longitude,
		  timezone: String(timezone),
        };

        // Set location details for weather forecast
        setLocation(locationData);

        // Sačuvaj podatke o lokaciji u localStorage
        localStorage.setItem('userLocation', JSON.stringify(locationData));

        // Generate SEF link based on city details
        const sefLink = `${window.location.origin}/${city.cn.toLowerCase()},${latitude},${longitude}`;
        window.history.pushState({}, '', sefLink); // Update the URL

        // Update weather forecast based on the city ID
		setIsLoading(true);
        getWeatherForecast(city.id);

        // Update date and time based on timezone
        updateDateTime(timezone); // Create a function to format and show date/time
      }
    } catch (error) {
      console.error('Error selecting city:', error);
    }

    // Clear search input after city selection
    setSearchTerm(''); // Reset search box
    setSearchResults([]); // Clear search results
  };

  // Function to update the date and time based on the timezone
  const updateDateTime = (timeZone) => {
    const userLang = localStorage.getItem('userLang') || 'en'; // Dobijamo jezik korisnika iz localStorage

    // Definišemo ručne mape za prevod dana u različite jezike
    const weekdayTranslations = {
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      rs: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
      hr: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
      el: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο']
    };

    // Postavljamo opcije za formatiranje
    const options = {
      timeZone,
      weekday: 'long', // Dan u nedelji u dugom formatu
      day: '2-digit',  // Dan sa dve cifre
      month: '2-digit', // Mesec sa dve cifre
      year: 'numeric', // Godina sa četiri cifre
      hour: '2-digit', // Sat u 24-časovnom formatu
      minute: '2-digit', // Minuti
      hour12: false, // 24-časovni format
    };

    const date = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
    const [weekdayPart, datePart, timePart] = formattedDate.split(', ');

    // Prevodimo dan u nedelji u zavisnosti od jezika
    const weekdayIndex = new Date(date).getDay(); // Dobijamo indeks dana u nedelji (0 - Sunday, 1 - Monday, ...)
    const translatedWeekday = weekdayTranslations[userLang][weekdayIndex];

    // Konstruisanje datuma sa prevedenim danom
    const dateTime = `${translatedWeekday}, ${datePart}, ${timePart}`;

    // Ažuriranje state-ova
    setDateTime(dateTime);

    // Parsiranje datuma i vremena za trenutni sat i datum
    const [day, month, year] = datePart.split('/'); // Parsiramo "07", "10", "2024"
    const currentHour = timePart.split(':')[0]; // Izvlačimo "21" iz "21:06"

    // Formiramo datum u formatu "YYYY-MM-DD"
    const currentDate = `${year}-${month}-${day}`;

    // Postavi sate i datum u state
    setCurrentHour(currentHour);
    setCurrentDate(currentDate);
  };

  
  const capitalizeWords = (str) => {
    return str
      .split(' ') // Razdvajanje stringa po razmacima
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Za svaku reč prvo slovo veliko
      .join(' '); // Ponovno spajanje reči u string
  };
  
  // Otvori mapu
  const openMap = () => {
    setIsMapOpen(true);
  };
  const openMap1 = () => {
    setIsMapOpen1(true);
  };

  // Zatvori mapu
  const closeMap = () => {
    setIsMapOpen(false);
  };
  const closeMap1 = () => {
    setIsMapOpen1(false);
  };
  
  const getSeoTitle = (countrycode, cityName) => {
	const savedLang = localStorage.getItem('userLang') || 'en';
    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    const capitalizedCityName = capitalizeFirstLetter(cityName);

    // SEO Translations
    const seoTranslations = {
      en: `Weather Forecast ${capitalizedCityName} - 7 days`,
      rs: `Vremenska Prognoza ${capitalizedCityName} - 7 dana`,
      hr: `Vremenska prognoza ${capitalizedCityName} - 7 dana`,
      el: `Πρόγνωση καιρού ${capitalizedCityName} - 7 ημέρες`,
    };

    // Vraćamo naslov baziran na jeziku korisnika ili engleski kao podrazumevani
    return seoTranslations[savedLang] || seoTranslations['en'];
  };
  
  const setSeo = (countrycode, cityName) => {
	  // Dohvati sačuvan jezik iz localStorage, ako nije postavljen, koristi 'en' kao podrazumevani jezik
	  const savedLang = localStorage.getItem('userLang') || 'en';
	  const userLocation = JSON.parse(localStorage.getItem('userLocation')) || {};
	  const locationName = userLocation.name || '';
	  const locationLatitude = userLocation.latitude || '';
	  const locationLongitude = userLocation.longitude || '';
	  
      // Capitalize city name for SEO purposes
      const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);
      const capitalizedCityName = capitalizeFirstLetter(cityName);

      // SEO Translations
      const seoTranslations = {
        en: `Weather Forecast ${capitalizedCityName} - 7 days | Weather2Umbrella LTD`,
        rs: `Vremenska Prognoza ${capitalizedCityName} - 7 dana | Weather2Umbrella LTD`,
        hr: `Vremenska prognoza ${capitalizedCityName} - 7 dana | Weather2Umbrella LTD`,
        el: `Πρόγνωση καιρού ${capitalizedCityName} - 7 ημέρες | Weather2Umbrella LTD`,
      };

      // Default to English if translation is missing
      const seoTitle = seoTranslations[savedLang] || seoTranslations['en'];
	  const canonicalUrl = `https://www.weather2umbrella.com/${locationName.toLowerCase()},${locationLatitude},${locationLongitude}`;

      // Return JSX with Helmet for SEO meta and title
      return (
        <Helmet>
		  <html lang={savedLang} />
          <meta charset="UTF-8" />
          <meta http-equiv="content-language" content={countrycode} />
          <meta name="description" content={seoTitle} />
		  <meta name="keywords" content="πρόγνωση καιρού 7 ημέρες, Θεσσαλονίκη, Αθήνα, Χαλκιδική, Vremenska Prognoza 7 dana, Beograd, Novi Sad, Zagreb, Sarajevo, Skopje, Weather Forecast 7 days, London, New York, Rome, Paris" />
          <meta name="copyright" content="Weather2Umbrella is a registered trademark of Weather2Umbrella LTD" />
          <meta name="author" content="Weather2Umbrella LTD" />
          <meta name="application-name" content="Weather2Umbrella" />
          <meta name="robots" content="index,follow" />
          <meta name="verify-v1" content="OHYCv3yPjXUwarlfJnajqs8MMggoKIjdF1+07EcuHJ0=" />
          <meta name="alexaVerifyID" content="IkE8dQag6U8_TEcTDdvCe4CKhbo" />
          <meta name="p:domain_verify" content="933810b0a8e2b49af0b4c8b597a065e1" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@W2U_LTD" />
		  <link rel="canonical" href={canonicalUrl} />
          <title>{seoTitle}</title>
        </Helmet>
      );
  };
  
  // 1.
  const setUnitsAndLanguage = useCallback((countrycode) => {
    let userLang;
    let tempUnits;
    let windSpeedUnits;

    switch (countrycode) {
      case 'rs':
        userLang = 'rs';
        break;
      case 'hr':
        userLang = 'hr';
        break;
      case 'el':
        userLang = 'el';
        break;
      default:
        userLang = 'en';
        break;
    }

    if (countrycode === 'us' || countrycode === 'gb') {
      tempUnits = 'F';
    } else {
      tempUnits = 'C';
    }

    if (countrycode === 'us') {
      windSpeedUnits = 'mph';
    } else if (countrycode === 'gr') {
      windSpeedUnits = 'beaufort';
    } else {
      windSpeedUnits = 'm/s';
    }

    // Postavi stanje
    setUserLang(userLang);
    setTempUnits(tempUnits);
    setWindSpeedUnits(windSpeedUnits);

    // Sačuvaj vrednosti u localStorage
    localStorage.setItem('userLang', userLang);
    localStorage.setItem('tempUnits', tempUnits);
    localStorage.setItem('windSpeedUnits', windSpeedUnits);

  }, []);


  // 2. Funkcija za vremensku prognozu
  const getWeatherForecast = useCallback((cityID) => {
    fetch(`/api/weather?cityID=${cityID}`)
      .then((response) => response.json())
      .then((data) => {
        setWeather(data);
        
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching weather forecast:', error);
        setIsLoading(false);
      });
  }, []);

  const getWeatherNotification = useCallback((cityID) => {
    fetch(`/api/weather?cityID=${cityID}`)
      .then((response) => response.json())
      .then((data) => {
        // Set weather data for notifications
        if (data && data.cityData && data.cityData.hourly) {
          localStorage.setItem('notificationWeatherData', JSON.stringify(data.cityData.hourly));
          console.log("Weather data for notifications set in localStorage");
        }
      })
      .catch((error) => {
        console.error('Error fetching weather forecast for notifications:', error);
      });
  }, []);

  // Function to fetch city data based on user input
  const fetchCityData = async (cityName) => {
    try {
      const response = await fetch('/api/searchCity', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cityName }),
      });

      const data = await response.json();
      setSearchResults(data); // Set search results
    } catch (error) {
      console.error('Error fetching city data:', error);
    } finally {
      //setIsSearching(false);
    }
  };
  
  // 3. Postavljanje default lokacije (London)
  const setDefaultLocation = useCallback(() => {
    const defaultLocation = {
      cityID: 802063,
      name: 'london',
      countrycode: 'gb',
      timezone: 'Europe/London',
      latitude: 51.5,
      longitude: -0.116667,
    };
    setLocation(defaultLocation);
    const newUrl = `${window.location.origin}/london,51.5,-0.116667`;
    window.history.pushState({}, '', newUrl);
    getWeatherForecast(defaultLocation.cityID);  // Poziv prognoze za London
  }, [getWeatherForecast]);
  
  // 4. Zahtev za geolokaciju korisnika
  const askForGeolocation = useCallback(() => {
   setIsLoading(true);
   if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        fetch(`/api/geolocation?lat=${latitude}&lon=${longitude}`)
          .then((response) => response.json())
          .then((data) => {
            const userLocation = {
              cityID: data.cityID,
              name: data.name,
              countrycode: data.countrycode,
              timezone: data.timezone,
              latitude: data.latitude,
              longitude: data.longitude,
            };
			localStorage.setItem('userLocation', JSON.stringify(userLocation));
            setLocation(userLocation);
			localStorage.setItem('isMyLocationID', data.cityID);
			setIsMyLocationID(data.cityID);

            // Ako je prva poseta, postavi jezik i jedinice
            //const isFirstVisit = !localStorage.getItem('hasVisited');
			const isFirstVisit = localStorage.getItem('hasVisited') === 'false';
            if (isFirstVisit) {
              setUnitsAndLanguage(userLocation.countrycode);
              localStorage.setItem('hasVisited', 'true');
            }

            // Kreiraj SEF link
            const newUrl = `${window.location.origin}/${encodeURIComponent(data.name)},${data.latitude},${data.longitude}`;
            window.history.pushState({}, '', newUrl);

            // Fetch weather data
            getWeatherForecast(data.cityID);

            // Postavi geolocationConsent na "granted"
            localStorage.setItem('geolocationConsent', 'granted');
            setGeolocationConsent('granted');
			setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching geolocation data:', error);
            setDefaultLocation();  // Postavi London ako dođe do greške
			setIsLoading(false);
          });
      },
      () => {
        localStorage.setItem('geolocationConsent', 'denied');
        setGeolocationConsent('denied');
        setDefaultLocation();  // Postavi London ako je geolokacija odbijena
		setIsLoading(false);
      }
    );
   } else {
     // Handle case where geolocation is not supported
     console.error("Geolocation is not supported by this browser.");
     setDefaultLocation();  // Postavi London ako geolokacija nije podržana
     setIsLoading(false);
   }
  }, [getWeatherForecast, setDefaultLocation, setUnitsAndLanguage]);

  // 5. Provera i dobijanje trenutne geolokacije korisnika
  const checkUserGeolocation = useCallback(() => {
    const locationData = localStorage.getItem('userLocation');
        
    if (locationData) {
      const parsedLocation = JSON.parse(locationData);
      setLocation(parsedLocation);
      // Kreiraj SEF link
      const newUrl = `${window.location.origin}/${encodeURIComponent(parsedLocation.name)},${parsedLocation.latitude},${parsedLocation.longitude}`;
      window.history.pushState({}, '', newUrl);
      getWeatherForecast(parsedLocation.cityID);  // Učitaj vremensku prognozu za pronađenu lokaciju
      setIsLoading(false);
    } else {
      askForGeolocation();  // Ako nema sačuvanih podataka, traži novu geolokaciju
    } 
  }, [getWeatherForecast, askForGeolocation]);

  // Method to handle new links and parse city info
  const handleNewLink = useCallback((url) => {
    setIsLoading(true);

    const parts = url.split('/').pop()?.split(',');

    if (parts && (parts.length === 2 || parts.length === 3)) {
      const latitude = parseFloat(parts[parts.length - 2]);
      const longitude = parseFloat(parts[parts.length - 1]);

      if (!isNaN(latitude) && !isNaN(longitude)) {
        let apiUrl = '';

        if (parts.length === 3) {
          const cityName = decodeURIComponent(parts[0]);
          apiUrl = `/api/convert-link?slug=${encodeURIComponent(`${cityName},${latitude},${longitude}`)}`;
        } else {
          apiUrl = `/api/geolocation?lat=${latitude}&lon=${longitude}`;
        }

        // Fetch city data from the API
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            setLocation({
              cityID: data.cityID,
              name: data.name,
              countrycode: data.countrycode,
              timezone: data.timezone,
              latitude: data.latitude,
              longitude: data.longitude,
            });

            // Save the location in localStorage
            localStorage.setItem('userLocation', JSON.stringify(data));

            // Update the URL
            const newUrl = `${window.location.origin}/${encodeURIComponent(data.name)},${data.latitude},${data.longitude}`;
            window.history.pushState({}, '', newUrl);

            // Fetch weather data
            getWeatherForecast(data.cityID);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching location data:', error);
            setDefaultLocation();
          });
      } else {
        setDefaultLocation();
      }
    } else {
      setDefaultLocation();
    }
  }, [getWeatherForecast, setDefaultLocation]);

  // Method to convert old links and get city data
  const convertOldLink = useCallback((oldLink) => {
    setIsLoading(true);

    if (!oldLink.includes('/')) {
      setDefaultLocation();
      return;
    }

    fetch(`/api/convert-link?slug=${encodeURIComponent(oldLink)}`)
      .then((response) => response.json())
      .then((data) => {
        setLocation({
          cityID: data.cityID,
          name: data.name,
          countrycode: data.countrycode,
          timezone: data.timezone,
          latitude: data.latitude,
          longitude: data.longitude,
        });

        // Save the location in localStorage
        localStorage.setItem('userLocation', JSON.stringify(data));

        // Update the URL
        const newUrl = `${window.location.origin}/${encodeURIComponent(data.name)},${data.latitude},${data.longitude}`;
        window.history.pushState({}, '', newUrl);

        // Fetch weather data
        getWeatherForecast(data.cityID);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error converting old link:', error);
        setDefaultLocation();
        setIsLoading(false);
      });
  }, [getWeatherForecast, setDefaultLocation]);

  const loadSavedPreferences = () => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const savedLang = localStorage.getItem('userLang');
      const savedTempUnits = localStorage.getItem('tempUnits');
      const savedWindSpeedUnits = localStorage.getItem('windSpeedUnits');
      const savedGeolocationConsent = localStorage.getItem('geolocationConsent');
      const savedThemeMod = localStorage.getItem('themeMod');

      if (savedLang) setUserLang(savedLang);
      if (savedTempUnits) setTempUnits(savedTempUnits);
      if (savedWindSpeedUnits) setWindSpeedUnits(savedWindSpeedUnits);
      if (savedGeolocationConsent) setGeolocationConsent(savedGeolocationConsent);
      if (savedThemeMod) setThemeMod(savedThemeMod);
    }
  };

  // Provera i postavljanje teme
  const checkThemeMode = useCallback(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const savedTheme = localStorage.getItem('themeMod');
      let themeToApply;
      if (savedTheme) {
        themeToApply = savedTheme;
      } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        themeToApply = 'dark';
      } else {
        themeToApply = 'white';
      }
      setThemeMod(themeToApply);
      document.documentElement.classList.add(themeToApply);
    }
  }, []);

  // Toggle između light i dark modova
  const toggleThemeMode = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const newTheme = themeMod === 'dark' ? 'white' : 'dark';
      setThemeMod(newTheme);
      localStorage.setItem('themeMod', newTheme);
      document.documentElement.classList.toggle('dark', newTheme === 'dark');
    }
  };

  // Provera statusa notifikacija
  const checkNotificationStatus = () => {
    if (typeof window !== 'undefined') {
      const savedNotificationStatus = localStorage.getItem('notificationStatus');
      setNotificationStatus(savedNotificationStatus);
    }
  };

  // Promena statusa notifikacija
  const toggleNotifications = () => {
    if (notificationStatus === 'granted') {
      localStorage.setItem('notificationStatus', 'denied');
      setNotificationStatus('denied');
    } else {
      askForNotificationPermission();
      // Nakon postavljanja dozvole za notifikacije, osiguraj se da su podaci ispravno ažurirani
      const notificationCityID = localStorage.getItem('NotificationCityID');
      const notificationCityName = localStorage.getItem('NotificationCityName');
      if (notificationCityID && notificationCityName) {
        setNotificationCityName(notificationCityName);
      }
    }
  };

  // Zahtev za dobijanje dozvole za notifikacije
  const askForNotificationPermission = () => {
	if (!("Notification" in window)) {
	  alert("This browser does not support desktop notifications.");
	  return;
	}
    Notification.requestPermission().then(permission => {
      localStorage.setItem('notificationStatus', permission);
      setNotificationStatus(permission);

      // Check if notificationWeatherData is already set
      if (!localStorage.getItem('notificationWeatherData')) {
        // If not, set all notification items
        localStorage.setItem('NotificationCityID', location.cityID.toString());
        localStorage.setItem('NotificationCityName', location.name);
        localStorage.setItem('notificationCountryCode', location.countrycode); // Add country code
        localStorage.setItem('notificationTimeZone', location.timezone); // Add timezone
        localStorage.setItem('notificationWeatherData', JSON.stringify(weather.cityData.hourly));
      }
    });
  };

  const handleNotificationCitySelect = (event) => {
    const selectedCity = JSON.parse(event.target.value);

    // Postavi notifikacione podatke u localStorage
    localStorage.setItem('notificationCityID', selectedCity.cityID);
    localStorage.setItem('notificationCityName', selectedCity.name);
    localStorage.setItem('notificationCountryCode', selectedCity.countrycode);
    localStorage.setItem('notificationTimeZone', selectedCity.timezone);
	
	setNotificationCityName(selectedCity.name);

    // Preuzmi prognozu za odabrani grad za notifikacije
    getWeatherNotification(selectedCity.cityID);
  };

  // Provera geolokacije
  const checkGeolocation = useCallback(() => {
    setIsLoading(true);
    if (typeof window !== 'undefined') {
      const consent = localStorage.getItem('geolocationConsent');
      setGeolocationConsent(consent);
        
      if (consent === 'granted') {
        checkUserGeolocation();  // Ako je geolokacija odobrena, proveri poziciju korisnika
      } else {
	    const location = JSON.parse(localStorage.getItem('userLocation'));
	    if (location && location.name && location.countrycode) {
	      checkUserGeolocation(); 
	    } else {
	      setDefaultLocation();  // Ako nije, postavi podrazumevanu lokaciju (London)
		  checkUserGeolocation(); 
	    }
        setIsLoading(false);
      } 
    } 
  }, [checkUserGeolocation, setDefaultLocation]);



  // Funkcija za uključivanje/isključivanje geolokacije
  const toggleGeolocation = () => {
	//setIsLoading(true); 
    const consent = localStorage.getItem('geolocationConsent');

    if (consent === 'granted') {
      localStorage.setItem('geolocationConsent', 'denied');
      setGeolocationConsent('denied'); // Ažuriraj stanje u React-u
      //setDefaultLocation();  // Postavi default lokaciju (London)
	  setIsLoading(false); 
    } else {
      localStorage.setItem('geolocationConsent', 'granted');
      setGeolocationConsent('granted'); // Ažuriraj stanje u React-u
	  setIsLoading(false);
      //askForGeolocation();  // Pokreni proces geolokacije
    }
  };

  // Promena temperature
  const onTempUnitsChange = (event) => {
    const newTempUnits = event.target.value;
    setTempUnits(newTempUnits);
    localStorage.setItem('tempUnits', newTempUnits);
  };

  // Promena brzine vetra
  const onWindSpeedUnitsChange = (event) => {
    const newWindSpeedUnits = event.target.value;
    setWindSpeedUnits(newWindSpeedUnits);
    localStorage.setItem('windSpeedUnits', newWindSpeedUnits);
  };

  // Promena jezika
  const onUserLangChange = (event) => {
    const newLang = event.target.value;
    setUserLang(newLang);
    localStorage.setItem('userLang', newLang);
  };

  // Funkcija koja rukuje klikom na Geolocate dugme
  const geolocate = useCallback(() => {
    if (geolocationConsent === 'denied' || !geolocationConsent) {
      //askForGeolocation();  // Ako je geolokacija isključena, zatraži dozvolu
    } else if (geolocationConsent === 'granted') {
      // Ako je geolokacija uključena, samo pokupi trenutnu poziciju
      askForGeolocation();
    }
  }, [geolocationConsent, askForGeolocation]);

  const Tooltip = ({ message, onClose }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <p className="text-lg font-bold text-red-600">{message}</p>
          <button className="mt-4 bg-red-500 text-white py-2 px-4 rounded" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    );
  };
  
  const handleDeleteCity = (city) => {
    const updatedCityList = cityList.filter((c) => c.cityID !== city.cityID); // Filtriraj gradove osim onog koji treba da se obriše
    setCityList(updatedCityList); // Ažuriraj state
    localStorage.setItem('cityList', JSON.stringify(updatedCityList)); // Ažuriraj localStorage
  };
  

  const CityListTooltip = ({ cityList, onSelectCity, onDeleteCity, onClose, themeMod, tooltipRef }) => {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        onClick={onClose} // Zatvori tooltip kada klikneš bilo gde van njega
      >
        <div
          ref={tooltipRef}
          className={`p-4 rounded-lg shadow-lg w-full max-w-sm mx-4 ${
            themeMod === 'dark' ? 'bg-cardDarkBg text-white' : 'bg-white text-gray-900'
          }`}
          onClick={(e) => e.stopPropagation()} // Zaustavi zatvaranje tooltipa klikom unutar njega
        >
          {cityList.length > 0 ? (
            <ul className="max-h-60 overflow-y-auto">
              {cityList.map((city) => (
                <li
                  key={city.cityID}
                  className={`cursor-pointer p-2 flex justify-between items-center hover:bg-gray-200 dark:hover:bg-gray-700 ${
                    themeMod === 'dark' ? 'text-white' : 'text-black'
                  }`}
                >
                  <span onClick={() => onSelectCity(city)}>
                    {city.name}, {city.countrycode}
                  </span>
                  {/* "X" ikona za brisanje koja menja boju prema temi */}
                  <span
                    onClick={() => onDeleteCity(city)}
                    className={`cursor-pointer ml-4 font-semibold ${
                      themeMod === 'dark' ? 'text-gray-400 hover:text-red-600' : 'text-gray-600 hover:text-red-800'
                    }`}
                  >
                    x
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className={themeMod === 'dark' ? 'text-gray-400' : 'text-gray-600'}>Your city list is empty.</p>
          )}
        </div>
      </div>
    );
  };
  
  const Loader = () => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <p className="text-gray-800">Loading data, please wait...</p>
        </div>
      </div>
    );
  };
  
  const SettingsTooltip = ({ 
    onClose, 
    themeMod, 
    toggleThemeMode, 
    userLang, 
    onUserLangChange, 
    tempUnits, 
    onTempUnitsChange, 
    windSpeedUnits, 
    onWindSpeedUnitsChange, 
    notificationCityName, 
    notificationStatus, 
    toggleNotifications, 
    handleNotificationCitySelect, 
    cityList, 
    geolocationConsent, 
    toggleGeolocation 
  }) => {
    return (
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        onClick={onClose} // Zatvori tooltip kada klikneš bilo gde van njega
      >
        <div
          className={`p-4 rounded-lg shadow-lg w-full max-w-sm mx-4 ${
            themeMod === 'dark' ? 'bg-cardDarkBg text-white' : 'bg-white text-gray-900'
          }`}
          onClick={(e) => e.stopPropagation()} // Zaustavi zatvaranje tooltipa klikom unutar njega
        >
          {/* Switch for Dark Mode */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Dark Mode</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={themeMod === 'dark'}
                onChange={toggleThemeMode}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>

          {/* Drop-down for Language Selection */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Select Language:</label>
            <select onChange={onUserLangChange} value={userLang} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="en">English</option>
              <option value="rs">Serbian</option>
              <option value="hr">Croatian</option>
              <option value="el">Greek</option>
            </select>
          </div>

          {/* Drop-down for Temperature Unit Selection */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Temperature Units:</label>
            <select onChange={onTempUnitsChange} value={tempUnits} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="C">°C</option>
              <option value="F">°F</option>
		      <option value="K">°K</option>
            </select>
          </div>

          {/* Drop-down for Wind Speed Unit Selection */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Wind Speed Units:</label>
            <select onChange={onWindSpeedUnitsChange} value={windSpeedUnits} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="ms">m/s</option>
              <option value="kmh">km/h</option>
		      <option value="mph">mph</option>
		      <option value="fts">fts</option>
              <option value="beaufort">Beaufort</option>
            </select>
          </div>
		  
		  {/* Separator Line */}
		  <div className="border-t border-gray-300 dark:border-gray-500 my-4"></div>

          {/* Switch for Notifications */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Enable Weather Alert ({notificationCityName ? notificationCityName : 'No city selected'})</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notificationStatus === 'granted'}
                onChange={toggleNotifications}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>

          {/* Drop-down for Notification City Selection */}
          <div className="mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Alert City:</label>
            <select onChange={handleNotificationCitySelect} className="p-2 border rounded bg-white dark:bg-gray-700 dark:text-gray-200">
              <option value="">-- Choose a city --</option>
              {cityList.map((city) => (
                <option key={city.cityID} value={JSON.stringify(city)}>
                  {city.name}, {city.countrycode}
                </option>
              ))}
            </select>
          </div>

          {/* Switch for Geolocation */}
          <div className="flex items-center mb-4">
            <label className="mr-4 text-gray-800 dark:text-gray-200">Enable Geolocation</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={geolocationConsent === 'granted'}
                onChange={toggleGeolocation}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 dark:peer-checked:bg-blue-500 transition-colors"></div>
              <span className="absolute left-1 top-1 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full peer-checked:border-white dark:bg-gray-900"></span>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const checkForWeatherAlert = useCallback(() => {
    const notificationWeatherData = JSON.parse(localStorage.getItem('notificationWeatherData'));

    if (notificationWeatherData && notificationWeatherData.length > 0) {
      // Iteriraj kroz prvih 24 sata vremenskih podataka
      notificationWeatherData.slice(0, 24).forEach((hourlyWeather) => {
        const alertCode = getAlert(hourlyWeather);
        const alertText = getAlertText(hourlyWeather);

        if (alertCode && alertText) {
          setAlertMessage(alertText); // Postavi alert poruku
          setShowTooltipAlert(true); // Prikaži tooltip
        }
      });
    }
  }, []);

  const addCityToList = async (city) => {
    const savedCities = JSON.parse(localStorage.getItem('cityList')) || [];

    // Proveri da li je grad već u listi
    if (!savedCities.some((savedCity) => savedCity.cityID === city.id)) {
      try {
        // Poziv API-ja za detalje o gradu
        const response = await fetch(`/api/cityDetails?cityId=${city.id}`);
        const cityDetails = await response.json();

        // Proveravamo da li su podaci dobijeni i validni
        if (cityDetails && cityDetails.length > 0) {
          const { latitude, longitude, timezone } = cityDetails[0];

          const newCity = {
            cityID: city.id,
            name: city.cn,
            countrycode: city.cc,
            latitude, // Dodajemo latitude
            longitude, // Dodajemo longitude
            timezone: String(timezone), // Dodajemo timezone
          };

          const updatedCities = [...savedCities, newCity];
          localStorage.setItem('cityList', JSON.stringify(updatedCities));
		  setCityList(updatedCities);

          alert(`${city.cn}, ${city.cc} added to city list with details!`);
        }
      } catch (error) {
        console.error("Error fetching city details:", error);
        alert("Failed to fetch city details.");
      }
    } else {
      alert(`${city.cn}, ${city.cc} is already in your city list.`);
    }
  };

  // Funkcija koja prikazuje prognozu za izabrani grad
  const handleCityListSelect = (selectedCity) => {
    localStorage.setItem('cityListId', selectedCity.cityID);
    localStorage.setItem('cityListName', selectedCity.name);
    localStorage.setItem('cityListCountrycode', selectedCity.countrycode);
    localStorage.setItem('cityListLatitude', selectedCity.latitude);
    localStorage.setItem('cityListLongitude', selectedCity.longitude);
    localStorage.setItem('cityListTimezone', selectedCity.timezone);
	
    // Ažuriraj stanje location kako bi se prikazale promene na interfejsu
    const locationData = {
      cityID: selectedCity.cityID,
      name: selectedCity.name,
      countrycode: selectedCity.countrycode,
      latitude: selectedCity.latitude,
      longitude: selectedCity.longitude,
      timezone: selectedCity.timezone,
    };

    // Sačuvaj sve podatke o gradu u localStorage pod ključem 'userLocation'
    localStorage.setItem('userLocation', JSON.stringify(locationData));

    // Ažuriraj stanje location kako bi se prikazale promene na interfejsu
    setLocation(locationData);

    // Generisanje SEF linka i prikaz podataka
    const sefLink = `${window.location.origin}/${selectedCity.name.toLowerCase()},${selectedCity.latitude},${selectedCity.longitude}`;
    window.history.pushState({}, '', sefLink); // Ažuriramo URL
	
    setIsLoading(true);
    // Možemo ovde pozvati funkciju za prikaz vremenske prognoze
    getWeatherForecast(selectedCity.cityID);

    // Zatvori tooltip nakon izbora grada
    setShowTooltip(false);
  };
  
  const handleDateClick = (date) => {
    setActiveDate(date);
    dateRefs.current[date]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest', // Prevents vertical scrolling
      inline: 'center', // Ensures horizontal centering
    });
	console.log('Date:', date);
  };
  
  useEffect(() => {
    if (weather?.cityData?.daily) {
      const firstDate = Object.keys(weather.cityData.daily)[0];
      setActiveDate(firstDate);
    }
  }, [weather]);
  
  // Funkcija za pronalaženje prognoze za trenutni sat
  const getHourlyForecastForCurrentTime = useCallback(() => {
    if (!currentHour || !currentDate || !weather?.cityData?.hourly) return null;

    // Generiši deo ključa u formatu 'YYYY-MM-DD HH'
    const forecastKeyPrefix = `${currentDate} ${currentHour}`;

    console.log('Generated forecast key prefix:', forecastKeyPrefix); // Provera generisanog prefiksa
    console.log('Available keys:', Object.keys(weather.cityData.hourly)); // Provera dostupnih ključeva

    // Pronađi prvi ključ koji počinje sa generisanim prefiksom
    const forecastKey = Object.keys(weather.cityData.hourly).find(key =>
      key.startsWith(forecastKeyPrefix)
    );

    // Dohvati prognozu ako je pronađen odgovarajući ključ
    const forecast = forecastKey ? weather.cityData.hourly[forecastKey] : null;

    if (!forecast) {
      console.log('No forecast found for the generated key prefix.');
    } else {
      console.log('Found forecast:', forecast);
    }

    return forecast;
  }, [currentHour, currentDate, weather]);

  
  // Funkcija za formatiranje datuma
  const formatDate = (dateString, formatType = 'short') => {
    const date = new Date(dateString);
    const userLang = localStorage.getItem('userLang') || 'en'; // Podrazumevano na 'en' ako nema vrednosti

    // Nazivi meseci i dana za različite jezike
    const monthNames = {
      en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      rs: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
      hr: ['Siječanj', 'Veljača', 'Ožujak', 'Travanj', 'Svibanj', 'Lipanj', 'Srpanj', 'Kolovoz', 'Rujan', 'Listopad', 'Studeni', 'Prosinac'],
      el: ['Ιανουάριος', 'Φεβρουάριος', 'Μάρτιος', 'Απρίλιος', 'Μάιος', 'Ιούνιος', 'Ιούλιος', 'Αύγουστος', 'Σεπτέμβριος', 'Οκτώβριος', 'Νοέμβριος', 'Δεκέμβριος']
    };

    const weekdayNames = {
      en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      rs: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
      hr: ['Nedjelja', 'Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'],
      el: ['Κυριακή', 'Δευτέρα', 'Τρίτη', 'Τετάρτη', 'Πέμπτη', 'Παρασκευή', 'Σάββατο']
    };

    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[userLang][date.getMonth()];
    const shortMonth = month.slice(0, 3); // Skraćena verzija meseca
    const weekday = weekdayNames[userLang][date.getDay()];
    const shortWeekday = weekday.slice(0, 3); // Skraćena verzija dana
    const hour = date.getHours().toString().padStart(2, '0') + 'h'; // Dobijanje sata sa sufiksom 'h'

    switch (formatType) {
      case 'short':
        return `${day}. ${shortMonth}`; // Primer: "Tue 08. Oct"
      case 'long':
        return `${weekday} ${day}. ${month}`; // Primer: "Tuesday 08. October"
      case 'hour':
        return hour; // Primer: "00h"
      case 'day':
        return shortWeekday; // Primer: "00h"
      default:
        return dateString; // Vraćamo originalni string ako formatType nije validan
    }
  };
  
  useEffect(() => {
    if (location && location.timezone) {
      updateDateTime(location.timezone);
    }
  }, [userLang, location]);

  useEffect(() => {
    const forecastHourly = getHourlyForecastForCurrentTime();
    setForecastForCurrentTime(forecastHourly);

  }, [getHourlyForecastForCurrentTime]);
  
  // Zatvori tooltip kada korisnik klikne izvan njega
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    // Auto-close nakon 10 sekundi
    const timeout = setTimeout(() => {
      setShowTooltip(false);
    }, 10000); // 10 sekundi

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      clearTimeout(timeout); // Očisti timeout kada se komponenta demontira
    };
  }, [tooltipRef]);
  
  useEffect(() => {
    // If location has timeZone, update the date/time
    if (location && location.timezone) {
      updateDateTime(location.timezone);
    }
  }, [location]); // Trigger this effect whenever location changes
  
  useEffect(() => {
    // Debounce logika za API poziv
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length > 1) {
        fetchCityData(searchTerm); // Poziv API-ja za pretragu gradova
      } else {
        setSearchResults([]); // Očisti rezultate ako je searchTerm prazan
      }
    }, 100); // Debounce period od 500ms

    // Funkcija za sakrivanje rezultata kada se klikne van pretrage
    const handleClickOutside = (event) => {
      if (!event.target.closest('.search-container')) {
        setSearchResults([]); // Sakrij rezultate kad se klikne izvan pretrage
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup funkcije
    return () => {
      clearTimeout(delayDebounceFn); // Očisti timeout ako korisnik nastavi da kuca
      document.removeEventListener('click', handleClickOutside); // Ukloni event listener
    };
  }, [searchTerm]);
  
  // Use useEffect to update the ref when weather changes
  useEffect(() => {
    // Prva provera odmah prilikom učitavanja
    if (notificationStatus === 'granted') {
	  getWeatherNotification(localStorage.getItem('notificationCityID')); // Osvežavanje podataka
      checkForWeatherAlert(); // Prva provera odmah
    }

    // Interval za proveru svakih 30 minuta
    const interval = setInterval(() => {
      if (notificationStatus === 'granted') {
		getWeatherNotification(localStorage.getItem('notificationCityID')); // Osvežavanje podataka
        checkForWeatherAlert();
      }
    }, 30 * 60 * 1000); // 30 minuta

    // Očisti interval kada se komponenta unmountuje
    return () => clearInterval(interval);
  }, [checkForWeatherAlert, notificationStatus, getWeatherNotification]); // Dodaj notificationStatus kao zavisnost

  useEffect(() => {
    // Učitaj vrednost iz localStorage kada se komponenta učita
    const storedLocationID = localStorage.getItem('isMyLocationID');
    setIsMyLocationID(storedLocationID);
  }, []);
  
  useEffect(() => {
    const fetchWeatherData = () => {
      // Dohvati userLocation iz localStorage i parsiraj ga
      const userLocation = localStorage.getItem('userLocation');
    
      if (userLocation) {
        try {
          const parsedLocation = JSON.parse(userLocation);
          const cityID = parsedLocation.cityID;

          if (cityID) {
            getWeatherForecast(cityID); // Pozovi funkciju sa cityID-om
          } else {
            console.error('City ID nije pronađen u userLocation.');
          }
        } catch (error) {
          console.error('Greška prilikom parsiranja userLocation iz localStorage:', error);
        }
      } else {
        console.log('userLocation nije pronađen u localStorage.');
      }
    };

    // Prvi poziv odmah nakon montaže
    fetchWeatherData();

    // Postavi interval za ponovni poziv svakih 20 minuta (1,200,000 milisekundi)
    const interval = setInterval(fetchWeatherData, 1200000);

    // Očisti interval prilikom demontaže komponente
    return () => clearInterval(interval);
  }, [getWeatherForecast]);
  
  useEffect(() => {
    // Fetch the movie URL from the backend API
    const fetchMovieUrl = async () => {
      try {
        const response = await fetch('/api/get-movie');
        if (!response.ok) {
          throw new Error('Failed to fetch movie');
        }
        const data = await response.json();
        setMovieUrl(data.movieURL); // Set the movie URL
      } catch (error) {
        console.error('Error fetching movie:', error);
        setError('Movie not available');
      }
    };

    fetchMovieUrl();
  }, []); // Fetch only once on component mount
  
  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {
      console.error('Adsbygoogle error:', error);
    }
  }, []);
  
  
  // Equivalent to ngOnInit
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const isFirstVisit = !localStorage.getItem('hasVisited');

      // If this is the user's first visit, set default units and language
      if (isFirstVisit) {
        setUnitsAndLanguage('default');
        localStorage.setItem('hasVisited', 'false');
      }

      // Load saved preferences
      loadSavedPreferences();
      checkThemeMode();
      checkNotificationStatus();

      const currentUrl = window.location.href;

      // Handle the different URL formats (new or old links)
      if (currentUrl.includes(',')) {
        handleNewLink(currentUrl); // New link format
      } else if (currentUrl.includes('-')) {
        convertOldLink(currentUrl); // Old link format
      } else {
        checkGeolocation();
      }
	  
	  const savedNotificationCityName = localStorage.getItem('notificationCityName');
      
	  if (savedNotificationCityName) {
      	setNotificationCityName(savedNotificationCityName);
      }
	  
      // Dodajemo logiku za SEO
      const location = JSON.parse(localStorage.getItem('userLocation'));
      if (location && location.name && location.countrycode) {
        // Pozivamo setSeo funkciju sa podacima iz localStorage
        setSeo(location.countrycode, location.name);
      }
	  
    }
  }, [checkGeolocation,checkThemeMode,convertOldLink,handleNewLink,setUnitsAndLanguage]);

  return (

<div className=" md:flex md:justify-center md:items-starts">
<div className="hidden md:flex items-start space-x-4 border border-gray-300 dark:border-gray-600 p-2 text-gray-800 dark:text-gray-200"><p>AdSense</p></div>
    <div className=" bg-lightBackground dark:bg-darkBackground">
      
	 
	  
	  {/* SEO Meta Tagovi */}
	      {location && location.countrycode && location.name && setSeo(location.countrycode, location.name)}  {/* Poziv SEO funkcije */}
			
	  {/* Tooltip prikaz ako je aktivan */}
	  {showTooltipAlert && (
	      <Tooltip
	        message={alertMessage}
	        onClose={() => setShowTooltipAlert(false)} // Klikom bilo gde zatvori tooltip
	      />
	  )}
	  
	  
<div className="bg-lightBackground dark:bg-darkBackground">
  {/* Loader Element */}
  {isLoading && <Loader />}
  {/* Prvi div sa tri elementa - raspored levo i desno */}
  <div className="flex justify-between items-center py-2">
    <div className="flex-1 px-2"> {/* Skroz levo */}
  <img
    src={themeMod === 'dark' ? '/images/logowhite.png' : '/images/logodarkgray.png'}
    alt="W2U logo"
    className="h-9 lg:h-10"
    style={{ maxWidth: '100%' }}// Osigurava da maksimalna širina ne prelazi 50%
  />
    </div>
    <div className="flex space-x-1  mr-2"> {/* Skroz desno */}

{/* Button za otvaranje settings tooltipa */}
<button onClick={() => setShowSettingsTooltip(!showSettingsTooltip)} className="bg-transparent text-gray-400">
  <FaCog className="w-6 h-6" />
</button>
<div className="relative">
{/* Tooltip sa podešavanjima */}
{showSettingsTooltip && (
  <SettingsTooltip
    onClose={() => setShowSettingsTooltip(false)}
    themeMod={themeMod}
    toggleThemeMode={toggleThemeMode}
    userLang={userLang}
    onUserLangChange={onUserLangChange}
    tempUnits={tempUnits}
    onTempUnitsChange={onTempUnitsChange}
    windSpeedUnits={windSpeedUnits}
    onWindSpeedUnitsChange={onWindSpeedUnitsChange}
    notificationCityName={notificationCityName}
    notificationStatus={notificationStatus}
    toggleNotifications={toggleNotifications}
    handleNotificationCitySelect={handleNotificationCitySelect}
    cityList={cityList}
    geolocationConsent={geolocationConsent}
    toggleGeolocation={toggleGeolocation}
  />
)}
</div>
    </div>
  </div>

  {/* Drugi div sa tri elementa - raspored različit u mobilnoj i desktop verziji */}
  <div className="flex justify-between items-center mb-2">
    <div className="flex px-2 space-x-3"> {/* Levi element levo u mobilnoj verziji */}
  {/* Search Box */}
<div className="relative">
  <input
    type="text"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    placeholder="Enter city name"
    className={`p-2 border border-gray-300 rounded-md ${themeMod === 'dark' ? 'bg-cardDarkBg text-white border-gray-500' : 'bg-white text-black'}`}
  />

  {/* Display Search Results */}
<ul className="absolute bg-white dark:bg-cardDarkBg border rounded mt-1 max-h-60 overflow-y-auto w-80 z-50 border-none">
  {searchResults.slice(0, 30).map((city) => (
    <li
      key={city.id}
      className={`cursor-pointer p-2 flex justify-between items-center hover:bg-gray-200 ${
        themeMod === 'dark' ? 'hover:bg-gray-700 text-white' : 'hover:bg-gray-300 text-black'
      }`}
    >
      <span onClick={() => onCitySelect(city)}>
        {city.cn}, {city.cc.toLowerCase()}
      </span>
      <button
        onClick={() => {
          addCityToList(city);
          setSearchTerm(''); // Resetuje search box
        }}
        className={`mr-4 text-xl font-semibold ${
          themeMod === 'dark' ? 'text-white-600 hover:text-red-600' : 'text-gray-500 hover:text-red-800'
        }`}
      >
        +
      </button>
    </li>
  ))}
</ul>
</div>
  {/* Geolocate Button */}
<button
  className={`bg-transparent ${geolocationConsent === 'granted' ? 'text-gray-400' : 'text-gray-300 dark:text-gray-600 cursor-not-allowed'}`}
  onClick={geolocationConsent === 'granted' ? geolocate : null}
  disabled={geolocationConsent !== 'granted'}
>
  <FaMapMarkerAlt className="w-6 h-6" />
</button>
  {/* Button za otvaranje tooltipa */}
  <button onClick={() => setShowTooltip(!showTooltip)} className="bg-transparent text-gray-400">
    <FaListAlt className="w-6 h-6" />
  </button>
  <div className="relative">
  {/* Tooltip sa gradovima */}
  {showTooltip && (
  <CityListTooltip
    cityList={cityList}
    onSelectCity={handleCityListSelect}
    onDeleteCity={handleDeleteCity} // Prosleđujemo funkciju za brisanje grada
    onClose={() => setShowTooltip(false)}
    themeMod={themeMod}
    tooltipRef={tooltipRef}
  />
  )}
  </div>
    </div>
    <div className="flex space-x-2 mr-2"> {/* Desni elementi desno u mobilnoj verziji */}


    </div>
  </div>
</div>
	  



{/* Display City Name and City ID */}
<div className="px-2 md:flex md:space-x-2">

{location && (
<div className=" bg-cardLightBg dark:bg-cardDarkBg p-4 rounded-lg shadow-md md:w-max md:h-max text-gray-600 dark:text-gray-300">
  {/* First Inner Div: DateTime, Location Name, CityID, and Map Button */}
  <div className="mb-2 leading-tight md:w-[320px]">
    {/* DateTime */}
	{dateTime && (
    <p className="text-gray-600 dark:text-gray-300 mb-1">{dateTime}</p>
    )}
    
    {/* Location Name, CityID, and Map Button */}
    <div className="flex items-center mb-1 leading-tight">
      <h2 className="text-xl md:text-2xl font-semibold text-gray-800 dark:text-gray-200 mr-2">
    {isMyLocationID === location?.cityID && (
      <FaMapMarkerAlt className="inline-block mr-2 text-blue-500" />
    )}
    {capitalizeWords(location.name)}, {location.countrycode}
      </h2>
      <span className="text-red-500 dark:text-red-300 mr-2 ml-6" onClick={openMap}>WeatherPro</span>
{/* Ikona za otvaranje mape sa Tailwind klasama za veličinu */}
<button className="bg-transparent text-red-400" onClick={openMap}>
  <FaMap className="w-6 h-6" /> {/* Koristite Tailwind klase za veličinu */}
</button>
<div className="relative">
{/* Prikaz mape ako je otvorena */}
{isMapOpen && (
  <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
    {/* Dugme za zatvaranje mape */}
    <button className="close-map" onClick={closeMap}>
      X
    </button>

    {/* Mapa koja zauzima ceo ekran */}
    <div className="w-full h-full">
      <WeatherMap />
    </div>
  </div>
)}
</div>
    </div>

    {/* Separator: Gray Line */}
    <div className="border-t border-gray-300 dark:border-gray-600 mt-1"></div>
  </div>

  {/* Second Div: Two Divs Side by Side */}
{forecastForCurrentTime ? (
  <div className="flex justify-between mb-2 leading-tight">
    
    {/* First Inner Div: Weather Icon and Temperature */}
    <div className="flex items-center">
      {/* Weather Icon URL */}
    <img 
      src={`/images/${forecastForCurrentTime.icon}.png`} 
      alt="Weather Icon" 
      className="w-20 h-20"
    />
    <span className="ml-2 text-4xl" style={{ color: forecastForCurrentTime.tmp !== null && forecastForCurrentTime.tmp !== undefined ? getTemperatureColor(forecastForCurrentTime.tmp, themeMod === 'dark') : '' }}>
      {convertTemperature(forecastForCurrentTime.tmp, tempUnits)}
    </span>
    </div>

    {/* Second Inner Div: Feels Like, UV, Wind Speed, Precipitation, Humidity */}
    <div className="space-y-1 text-left ml-8">
      <div className="leading-tight">feels: <span style={{ color: forecastForCurrentTime.flt !== null && forecastForCurrentTime.flt !== undefined ? getTemperatureColor(forecastForCurrentTime.flt, themeMod === 'dark') : '' }}>
	          {convertTemperature(forecastForCurrentTime.flt, tempUnits)}
	        </span></div>
      <div className="leading-tight">UV: <span style={{ color: forecastForCurrentTime.uv ? getUVColor(forecastForCurrentTime.uv) : '-' }}>
	          {forecastForCurrentTime.uv ? forecastForCurrentTime.uv : '-'}
	        </span></div>
      <div className="leading-tight"><span>{forecastForCurrentTime.ws ? `${convertWindSpeed(forecastForCurrentTime.ws, windSpeedUnits)} ${getDirectionFromAngle(forecastForCurrentTime.wd)}` : '-'}</span></div>
      <div className="leading-tight">pcp: <span className="text-blue-400">{forecastForCurrentTime.pcp ? `${forecastForCurrentTime.pcp} mm` : '-'}</span></div>
      <div className="leading-tight">rh: <span>{forecastForCurrentTime.rh ? `${forecastForCurrentTime.rh} %` : '-'}</span></div>
    </div>
  </div>
  ) : (
    <p>No forecast data available.</p>
  )}
  {/* Third Div: Weather Description and Wind Description */}
{forecastForCurrentTime ? (
  <div className="text-left mt-1 leading-tight">
<p>
  {forecastForCurrentTime.icon ? WeatherDescription.getWeatherDescription(forecastForCurrentTime.icon) : ''}
  {forecastForCurrentTime.ws ? `, ${getWindDescription(forecastForCurrentTime.ws)}` : '-'}
</p>
  </div>
) : (
  <p>No forecast data available.</p>
)}
</div>
)}
{/* Map Preview Div: Only visible on desktop */}
<div className="hidden md:block w-[510px] h-[246px] bg-cardLightBg dark:bg-cardDarkBg rounded-lg shadow-md">
  <div className="w-[493px] h-[231px] mt-2 ml-2">
    <WeatherMap /> {/* Leaflet map preview */}
  </div>
</div>
</div>



{/* banner */}
<div className="flex px-2 md:flex mt-4 justify-center items-center block md:hidden">
 <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2justify-center items-center">
		banner mobile
 </div>
</div>

{/* Dinamički Naslov za prognozu */}
 <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
   {location && getSeoTitle(userLang, location.name)}
 </p>

 {/* Display Weather Forecast */}
 <div className="px-2 md:flex md:space-x-4">
   <div className="bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[870px] md:h-max">
     <section className="overflow-x-auto">
<div className="p-2 flex min-w-max space-x-4 justify-center items-center md:w-[854px]">
  {weather && weather.cityData && weather.cityData.daily &&
    Object.entries(weather.cityData.daily)
      .filter(([key, day]) => key && day)
      .map(([key, day]) => (
        <div
          key={key}
          ref={(el) => (dateRefs.current[key] = el)} // Store the ref for each date
          className={`p-2 text-lg text-gray-600 dark:text-gray-300 rounded-lg border border-gray-100 dark:border-gray-700 shadow-md cursor-pointer flex flex-col items-center justify-center ${
            key === activeDate
              ? 'bg-activeLightBg dark:bg-activeDarkBg'
              : 'bg-cardLightBg dark:bg-cardDarkBg'
          }`}
          onClick={() => handleDateClick(key)} // Use the updated function
        >
          <p className="text-l text-gray-700 dark:text-gray-200 leading-tight">
            {formatDate(key, 'day')}
          </p>
          <p className="text-l text-gray-700 dark:text-gray-200 leading-tight">
            {formatDate(key, 'short')}
          </p>
          <img
            src={`/images/${day.icon}.png`}
            alt="Weather Icon"
            className="w-12 h-12"
          />
          <p className="md:text-base space-x-2">
            <span style={{ color: day.tmax !== null && day.tmax !== undefined ? getTemperatureColor(day.tmax, themeMod === 'dark') : '' }}>
              {convertTemperature(day.tmax, tempUnits)}
            </span>
            <span style={{ color: day.tmin !== null && day.tmin !== undefined ? getTemperatureColor(day.tmin, themeMod === 'dark') : '' }}>
              {convertTemperature(day.tmin, tempUnits)}
            </span>
          </p>
          <p className="md:text-base">
            {day.ws ? `${convertWindSpeed(day.ws, windSpeedUnits)} ${getDirectionFromAngle(day.wd)}` : '-'}
          </p>
          <p className="md:text-base">
            UV: <span style={{ color: day.uv ? getUVColor(day.uv) : '' }}>
              {day.uv ? day.uv : '-'}
            </span>
          </p>
        </div>
      ))}
</div>
       
     </section>
   </div>
 </div>

 {/* Hourly Forecast Display */}
 <p className="text-lg lg:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2 mt-4 px-2">
   {formatDate(activeDate, 'long')}
 </p>
<div className="px-2 md:flex md:space-x-4" {...swipeHandlers}>
  <div className="bg-cardLightBg dark:bg-cardDarkBg rounded-lg shadow-md md:w-max md:h-max">
    <section className="mt-2 md:ml-2">
      {/* Header row: visible only on desktop */}
      <div className="hidden md:flex px-2 space-x-4 text-gray-500 dark:text-gray-400 text-sm">
        <div className="md:w-[40px] flex md:justify-center md:items-center leading-tight">h</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">icon</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">tmp</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">feels</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">alerts</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">uv</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">pcp</div>
        <div className="md:w-[80px] flex md:justify-center md:items-center leading-tight">humid</div>
        <div className="md:w-[110px] flex md:justify-center md:items-center leading-tight">wind</div>
      </div>

      <div className="grid grid-cols-1 gap-1">
        {weather && weather.cityData && weather.cityData.hourly && activeDate &&
          (() => {
            const dateOnly = activeDate.split(' ')[0];

            return Object.entries(weather.cityData.hourly)
              .filter(([key]) => key.startsWith(dateOnly))
              .map(([key, forecast], index) => (
                <React.Fragment key={key}>
                  {/* Insert AdSense banner after the 12th hour */}
                  {index === 13 && (
                    <div className="w-full flex justify-center items-center mt-2 mb-2">
                      {/* Responsive AdSense for desktop, 336x250 for mobile */}
                      <div className="hidden md:flex justify-center items-center">
                        {/* Responsive AdSense banner for desktop horizontalni*/}
						
                        <ins className="adsbygoogle"
                             style={{ display: 'block' }}
                             data-ad-client="ca-pub-3940256099942544"
                             data-ad-slot="1234567890"
                             data-ad-format="auto"
                             data-full-width-responsive="true"></ins>
                      </div>
                      <div className="flex justify-center items-center block md:hidden">
                        {/* 336x250 AdSense banner for mobile 2 */}
						
                        <ins className="adsbygoogle"
                             style={{ display: 'inline-block', width: '336px', height: '250px' }}
                             data-ad-client="ca-pub-3940256099942544"
                             data-ad-slot="1234567890"></ins>
                      </div>
                    </div>
                  )}

                  {/* Hourly weather data */}
                  <div className={`p-2 md:mr-2 rounded-lg shadow-md text-gray-600 dark:text-gray-300 flex justify-between ${
                    key.startsWith(`${currentDate} ${currentHour}`)
                      ? 'bg-activeLightBg dark:bg-activeDarkBg'
                      : 'bg-cardLightBg dark:bg-cardDarkBg'
                  }`}>
                    {/* Left div: horizontally aligned data */}
                    <div className="flex items-center space-x-4">
                      <p className="font-semibold md:text-sm md:flex md:w-[40px] items-center">{formatDate(key, 'hour')}</p>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <img src={`/images/${forecast.icon}.png`} alt="Weather Icon" className="w-10 h-10" />
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <span className="text-xl" style={{ color: forecast.tmp !== null && forecast.tmp !== undefined ? getTemperatureColor(forecast.tmp, themeMod === 'dark') : '' }}>
                          {convertTemperature(forecast.tmp, tempUnits)}
                        </span>
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        <span style={{ color: forecast.flt !== null && forecast.flt !== undefined ? getTemperatureColor(forecast.flt, themeMod === 'dark') : '' }}>
                          {convertTemperature(forecast.flt, tempUnits)}
                        </span>
                      </div>
                      <div className="md:w-[80px] md:flex md:items-center md:justify-center leading-tight">
                        {getAlert(forecast) && (
                          <img src={`/images/${getAlert(forecast)}${themeMod === 'dark' ? 'd.png' : '.png'}`} alt="Alert Icon" className="w-8 h-8" />
                        )}
                      </div>
                      <div className="hidden md:flex items-center space-x-4">
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          <span style={{ color: forecast.uv ? getUVColor(forecast.uv) : '' }}>
                            {forecast.uv ? forecast.uv : ''}
                          </span>
                        </div>
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          <span className="text-blue-400">{forecast.pcp ? `${forecast.pcp} mm` : ''}</span>
                        </div>
                        <div className="md:w-[80px] flex md:items-center md:justify-center">
                          {forecast.rh ? `${forecast.rh} %` : '-'}
                        </div>
                        <div className="md:w-[110px] flex md:items-center md:justify-center">
                          {forecast.ws ? `${convertWindSpeed(forecast.ws, windSpeedUnits)} ${getDirectionFromAngle(forecast.wd)}` : ''}
                        </div>
                      </div>
                    </div>
                    {/* Right div: vertically aligned data (Visible only on mobile) */}
                    <div className="block md:hidden space-y-1 text-right text-sm mr-1">
                      <div className="leading-tight">
                        UV: <span style={{ color: forecast.uv ? getUVColor(forecast.uv) : '' }}>{forecast.uv ? forecast.uv : '-'}</span>
                      </div>
                      <div className="leading-tight">
                        pcp: <span className="text-blue-400">{forecast.pcp ? `${forecast.pcp} mm` : '-'}</span>
                      </div>
                      <div className="leading-tight">
                        rh: {forecast.rh ? `${forecast.rh} %` : '-'}
                      </div>
                      <div className="leading-tight">
                        {forecast.ws ? `${convertWindSpeed(forecast.ws, windSpeedUnits)} ${getDirectionFromAngle(forecast.wd)}` : '-'}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ));
          })()
        }
      </div>
    </section>
  </div>
</div>

		
<div className="flex px-2 md:flex mt-4 justify-center items-center md:justify-center md:items-center">
 <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center">
		banner
 </div>
</div>
		
<div className="flex px-2 md:flex mt-4 justify-center items-center md:justify-center md:items-center">
 <div className="flex bg-cardLightBg dark:bg-cardDarkBg p-2 rounded-lg shadow-md px-2 md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center">
		film
 </div>
</div>
		
<div className="flex px-2 md:flex mt-4 justify-center items-center md:justify-center md:items-center">
 <div className="flex bg-lightBackground dark:bg-darkBackground p-2 px-2 md:w-[870px] md:h-max justify-center items-center md:justify-center md:items-center">
		footer
 </div>
</div>

      
    </div>
<div className="hidden md:flex items-start space-x-4 border border-gray-300 dark:border-gray-600 p-2 text-gray-800 dark:text-gray-200"><p>AdSense</p></div>
</div>
  );
};

export default App;

