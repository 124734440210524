import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-velocity/dist/leaflet-velocity.css';
import 'leaflet-velocity-ts/dist/leaflet-velocity.js';
import './WeatherMap.css';

const WeatherMap = () => {
  const [map, setMap] = useState(null);

  useEffect(() => {
    // Inicijalizacija mape
    const mapInstance = L.map('map', {
      zoomControl: false,
    }).fitWorld();

    // Set map state
    setMap(mapInstance);

    // Dodavanje osnovnog sloja pločica (tiles)
    const tiles = L.tileLayer('https://cdn.weather2umbrella.com/web/world_tiles/world_grey/{z}/{x}/{y}.png', {
      minZoom: 2,
      maxZoom: 8,
      tms: true,
      opacity: 0.9,
    }).addTo(mapInstance);

    // Funkcija za dodavanje GeoJSON sloja
    const addWorldGeoJsonLayer = () => {
      const geoJsonUrl = 'https://cdn.weather2umbrella.com/web/world/world.json';
      fetch(geoJsonUrl)
        .then((response) => response.json())
        .then((data) => {
          L.geoJSON(data, {
            style: function (feature) {
              return {
                color: '#ffffff',
                weight: 1,
                opacity: 0.35,
                fillColor: '#ff7800',
                fillOpacity: 0,
              };
            },
          }).addTo(mapInstance);
        })
        .catch((err) => console.error('Error loading GeoJSON:', err));
    };

    addWorldGeoJsonLayer();

    // Postavljanje početnog prikaza mape
    const lat = parseFloat(localStorage.getItem('latitude')) || 51.5;
    const lon = parseFloat(localStorage.getItem('longitude')) || -0.116667;
    const zoomLevel = 5;
    mapInstance.setView([lat, lon], zoomLevel);

    // Funkcija za promenu sloja vremenskih podataka
    const changeWeatherLayer = (dataType, date, hour, weatherType) => {
      const layerUrl = `https://cdn.weather2umbrella.com/web/${dataType}/gfs/world/${date}/${hour}/${weatherType}/{z}/{x}/{y}.png`;
      let newLayer = L.tileLayer(layerUrl, {
        minZoom: 2,
        maxZoom: 8,
        tms: true,
        opacity: 0,
      }).addTo(mapInstance);

      let opacity = 0;
      const interval = setInterval(() => {
        opacity += 0.1;
        newLayer.setOpacity(opacity);

        if (opacity >= 0.9) {
          clearInterval(interval);
        }
      }, 30);
    };

    // Kontrola za prikaz modela na osnovu nivoa zuma
    const mapModelControl = L.control({ position: 'topleft' });
    mapModelControl.onAdd = function () {
      const div = L.DomUtil.create('div', 'map-model');
      div.style.backgroundColor = 'rgba(0, 0, 255, 0.3)';
      div.style.color = 'white';
      div.style.padding = '5px 10px';
      this.update(div);
      return div;
    };

    mapModelControl.update = function (div) {
      const zoom = mapInstance.getZoom();
      let modelText = zoom > 5 ? 'NMMB' : zoom === 5 ? 'NMMB-GFS' : 'GFS';
      div.innerHTML = modelText;
    };

    mapInstance.addControl(mapModelControl);
    mapInstance.on('zoomend', () => mapModelControl.update(mapModelControl._div));

    // Učitavanje vetrovnih podataka
    const loadWindData = (date, hour, weatherType) => {
      const url = `https://cdn.weather2umbrella.com/web/json/gfs/world/${date}/${hour}/${weatherType}/${weatherType}_V2.json`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          const velocityLayer = L.velocityLayer({
            displayValues: true,
            data: data,
            velocityScale: 0.01,
            maxVelocity: 15,
          });
          velocityLayer.addTo(mapInstance);
        })
        .catch((error) => console.error('Error loading wind data:', error));
    };

    // Dodavanje sloja sa gradovima na osnovu nivoa zuma
    const loadGeoJsonByZoom = (zoomLevel) => {
      const fileName = `${zoomLevel}.json`;
      const url = `https://cdn.weather2umbrella.com/web/cities/${fileName}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (window.geoJsonLayer) {
            mapInstance.removeLayer(window.geoJsonLayer);
          }

          window.geoJsonLayer = L.geoJSON(data, {
            pointToLayer: (feature, latlng) => {
              const markerHtml = `
                <div style="position: relative; text-align: center;">
                  <div style="background-color: rgba(255, 255, 255, 0.5); padding: 2px; font-size: 12px;">
                    &nbsp;
                  </div>
                  <div style="color: white; font-size: 14px;">
                    ${feature.properties.city}
                  </div>
                </div>`;
              return L.marker(latlng, { icon: L.divIcon({ className: 'my-custom-icon2', html: markerHtml }) });
            },
          }).addTo(mapInstance);
        })
        .catch((error) => console.error('Error loading GeoJSON data:', error));
    };

    mapInstance.on('zoomend', () => {
      const zoomLevel = mapInstance.getZoom();
      loadGeoJsonByZoom(zoomLevel);
    });

    return () => {
      mapInstance.remove();
    };
  }, []);

  useEffect(() => {
    const handleLocationChange = () => {
      const lat = parseFloat(localStorage.getItem('latitude')) || 51.5;
      const lon = parseFloat(localStorage.getItem('longitude')) || -0.116667;

      if (map) {
        map.setView([lat, lon], 5); // Ažuriraj prikaz mape prema promenama u lat/lon
      }
    };

    window.addEventListener('storage', handleLocationChange);

    handleLocationChange();

    return () => {
      window.removeEventListener('storage', handleLocationChange);
    };
  }, [map]);

  return <div id="map" style={{ height: '100vh', width: '100%' }} />;
};

export default WeatherMap;




