// WeatherUtils.js
// Definišemo funkcije za generisanje alert kodova i tekstova

export const getAlert = (weather) => {
  if (weather.icon.trim() === "11" && weather.pcp > 1.5) return "a04b";
  if (weather.icon.trim() === "13" && weather.pcp > 0.6) return "a05";
  if ((weather.icon.trim() === "14" || weather.icon.trim() === "15") && weather.tmp < -1) return "a05";
  if (weather.pcp > 1.5) return "a03";
  if (weather.ws > 10) return "a02";
  if (weather.tmp > 32 && weather.rh > 39) return "a06";
  if (weather.tmp > 37 && weather.uv > 6.5) return "a06";
  if (weather.tmp > 36 && weather.uv > 7.0) return "a01";
  if (weather.tmp > 33 && weather.uv > 9) return "a01";
  if (weather.uv > 9.0) return "a01";
  if (weather.tmp < -1 && weather.pcp > 0.5) return "a04";
  if (weather.ws > 7 && weather.pcp > 1.2) return "a05";
  return null;
};

export const getAlertText = (weather) => {
  if (weather.icon.trim() === "11" && weather.pcp > 1.5) return "Heavy snowfall!";
  if (weather.icon.trim() === "13" && weather.pcp > 0.6) return "Stormy wind with rain!";
  if ((weather.icon.trim() === "14" || weather.icon.trim() === "15") && weather.tmp < -1) return "Fog, icy day!";
  if (weather.pcp > 1.5 && weather.tmp > 1) return "Heavy rain!";
  if (weather.ws > 10) return "Strong wind!";
  if (weather.tmp > 32 && weather.rh > 39) return "Extremely warm, increased humidity in the air!";
  if (weather.tmp > 37 && weather.uv > 6.5) return "Very hot, high UV index!";
  if (weather.tmp > 36 && weather.uv > 7.0) return "Very hot, high UV index!";
  if (weather.tmp > 33 && weather.uv > 9) return "Very hot, high UV index!";
  if (weather.uv > 9.0) return "Very hot, high UV index!";
  if (weather.tmp < -1 && weather.pcp > 0.5) return "Icy and slippery road, cold day with prec!";
  if (weather.ws > 7 && weather.pcp > 1.2) return "Stormy wind with prec!";
  return null;
};

// Temperature ranges for Light Mode
const temperatureRangesLight = [
  { range: [-Infinity, -42], color: "B224B2" },
  { range: [-41, -40], color: "A724B2" },
  { range: [-39, -35], color: "9B24B2" },
  { range: [-34, -30], color: "8F24B2" },
  { range: [-29, -28], color: "7E24B2" },
  { range: [-27, -26], color: "6D24B2" },
  { range: [-25, -24], color: "5D24B2" },
  { range: [-23, -22], color: "4C24B2" },
  { range: [-21, -20], color: "432DB2" },
  { range: [-19, -18], color: "332DB2" },
  { range: [-17, -16], color: "2D36B2" },
  { range: [-15, -14], color: "2D45B2" },
  { range: [-13, -12], color: "305BBF" },
  { range: [-11, -10], color: "2666BF" },
  { range: [-9, -8], color: "2678BF" },
  { range: [-7, -6], color: "268ABF" },
  { range: [-5, -4], color: "269CBF" },
  { range: [-3, -2], color: "24A2B2" },
  { range: [-1, 0], color: "21A6A6" },
  { range: [1, 2], color: "21A68B" },
  { range: [3, 4], color: "21A671" },
  { range: [5, 6], color: "21A656" },
  { range: [7, 8], color: "21A63C" },
  { range: [9, 10], color: "21A621" },
  { range: [11, 12], color: "3CA621" },
  { range: [13, 14], color: "4CA611" },
  { range: [15, 16], color: "6AA611" },
  { range: [17, 18], color: "89AB00" },
  { range: [19, 20], color: "B8B800" },
  { range: [21, 22], color: "CCA300" },
  { range: [23, 24], color: "CC7E0A" },
  { range: [25, 26], color: "CC5E14" },
  { range: [27, 28], color: "CC3914" },
  { range: [29, 30], color: "BF1D1D" },
  { range: [31, 32], color: "C4142B" },
  { range: [33, 34], color: "C71444" },
  { range: [35, 36], color: "C9145D" },
  { range: [37, 38], color: "CC1476" },
  { range: [39, 40], color: "CC148F" },
  { range: [41, 45], color: "CC149E" },
  { range: [46, Infinity], color: "CC14AD" }
];

// Temperature ranges for Dark Mode
const temperatureRangesDark = [
  { range: [-Infinity, -42], color: "FF33FF" },
  { range: [-41, -40], color: "EE33FF" },
  { range: [-39, -35], color: "DD33FF" },
  { range: [-34, -30], color: "CC33FF" },
  { range: [-29, -28], color: "B433FF" },
  { range: [-27, -26], color: "9C33FF" },
  { range: [-25, -24], color: "8C40FF" },
  { range: [-23, -22], color: "7F4DFF" },
  { range: [-21, -20], color: "6A4DFF" },
  { range: [-19, -18], color: "554DFF" },
  { range: [-17, -16], color: "4D58FF" },
  { range: [-15, -14], color: "4D6DFF" },
  { range: [-13, -12], color: "4079FF" },
  { range: [-11, -10], color: "3388FF" },
  { range: [-9, -8], color: "33A0FF" },
  { range: [-7, -6], color: "33B8FF" },
  { range: [-5, -4], color: "33CFFF" },
  { range: [-3, -2], color: "33E7FF" },
  { range: [-1, 0], color: "33FFFF" },
  { range: [1, 2], color: "33FFD6" },
  { range: [3, 4], color: "33FFAD" },
  { range: [5, 6], color: "33FF85" },
  { range: [7, 8], color: "33FF5C" },
  { range: [9, 10], color: "33FF33" },
  { range: [11, 12], color: "5CFF33" },
  { range: [13, 14], color: "85FF33" },
  { range: [15, 16], color: "A3FF19" },
  { range: [17, 18], color: "CCFF00" },
  { range: [19, 20], color: "FFFF00" },
  { range: [21, 22], color: "FFCC00" },
  { range: [23, 24], color: "FF9E0D" },
  { range: [25, 26], color: "FF7519" },
  { range: [27, 28], color: "FF4719" },
  { range: [29, 30], color: "FF2626" },
  { range: [31, 32], color: "FF1A38" },
  { range: [33, 34], color: "FF1A57" },
  { range: [35, 36], color: "FF1A75" },
  { range: [37, 38], color: "FF1994" },
  { range: [39, 40], color: "FF19B2" },
  { range: [41, 45], color: "FF19C6" },
  { range: [46, Infinity], color: "FF19D9" }
];

// Function to get the temperature color based on the current theme
export const getTemperatureColor = (temp, isDarkMode) => {
  const temperatureRanges = isDarkMode ? temperatureRangesDark : temperatureRangesLight;
  
  for (const { range, color } of temperatureRanges) {
    if (temp >= range[0] && temp <= range[1]) {
      return `#${color}`; // Return color as hex
    }
  }
  
  return '#000000'; // Default color if temp doesn't fit any range
};

// Function to get UV color
export const getUVColor = (uv) => {
  if (uv >= 0.0 && uv <= 1.9) return "#289500";
  if (uv >= 2.0 && uv <= 4.9) return "#ff9600";
  if (uv >= 5.0 && uv <= 7.9) return "#f85900";
  return "#d80010";
};

// Konverzija brzine vetra na osnovu zadate jedinice
export const convertWindSpeed = (speed, unit) => {
  const formattedSpeed = (speed) => {
    if (speed === 0) {
      return "-";
    }
    return speed % 1 === 0 ? speed.toFixed(0) : speed.toFixed(1);
  };

  switch (unit) {
    case 'ms':
      return `${formattedSpeed(speed)} m/s`;
    case 'kmh':
      return `${formattedSpeed(speed * 3.6)} km/h`;
    case 'knots':
      return `${formattedSpeed(speed * 1.944)} knots`;
    case 'mph':
      return `${formattedSpeed(speed * 2.23694)} mph`;
    case 'fts':
      return `${formattedSpeed(speed * 3.28084)} ft/s`;
    case 'beaufort':
      return `${windSpeedToBeaufort(speed)} bf`;
    default:
      return `${formattedSpeed(speed)} m/s`; // Podrazumevano je m/s
  }
};

// Konverzija brzine vetra u Beaufort skalu
const windSpeedToBeaufort = (speed) => {
  const speeds = [0.3, 1.6, 3.4, 5.5, 8.0, 10.8, 13.9, 17.2, 20.8, 24.5, 28.5, 32.7];
  for (let i = 0; i < speeds.length; i++) {
    if (speed < speeds[i]) {
      return i;
    }
  }
  return 12; // Hurricane
};

// Konverzija temperature na osnovu zadate jedinice
export const convertTemperature = (temperature, unitString) => {
  switch (unitString) {
    case 'C':
      return `${temperature.toFixed(0)}°C`; // Celzijus
    case 'F':
      return `${((temperature * 9) / 5 + 32).toFixed(0)}°F`; // Farenhajt
    case 'K':
      return `${(temperature + 273.15).toFixed(0)}°K`; // Kelvin
    default:
      throw new Error("Unknown temperature unit");
  }
};

export const WindDirection = {
  O: 'o',
  N: 'N',
  NNE: 'NNE',
  NE: 'NE',
  ENE: 'ENE',
  E: 'E',
  ESE: 'ESE',
  SE: 'SE',
  SSE: 'SSE',
  S: 'S',
  SSW: 'SSW',
  SW: 'SW',
  WSW: 'WSW',
  W: 'W',
  WNW: 'WNW',
  NW: 'NW',
  NNW: 'NNW',
};

export const getLongName = (direction) => {
  const longNames = {
    [WindDirection.O]: '',
    [WindDirection.N]: 'N',
    [WindDirection.NNE]: 'NNE',
    [WindDirection.NE]: 'NE',
    [WindDirection.ENE]: 'ENE',
    [WindDirection.E]: 'E',
    [WindDirection.ESE]: 'ESE',
    [WindDirection.SE]: 'SE',
    [WindDirection.SSE]: 'SSE',
    [WindDirection.S]: 'S',
    [WindDirection.SSW]: 'SSW',
    [WindDirection.SW]: 'SW',
    [WindDirection.WSW]: 'WSW',
    [WindDirection.W]: 'W',
    [WindDirection.WNW]: 'WNW',
    [WindDirection.NW]: 'NW',
    [WindDirection.NNW]: 'NNW',
  };
  return longNames[direction] || 'Unknown';
};

export const getDirectionFromAngle = (windAngle) => {
  const directions = [
    { direction: WindDirection.O, range: [0.0, 0.1] },
    { direction: WindDirection.N, range: [349.5, 360.0] },
    { direction: WindDirection.N, range: [0.0, 11.5] },
    { direction: WindDirection.NNE, range: [11.5, 33.5] },
    { direction: WindDirection.NE, range: [33.5, 56.5] },
    { direction: WindDirection.ENE, range: [56.5, 78.5] },
    { direction: WindDirection.E, range: [78.5, 101.5] },
    { direction: WindDirection.ESE, range: [101.5, 123.5] },
    { direction: WindDirection.SE, range: [123.5, 146.5] },
    { direction: WindDirection.SSE, range: [146.5, 168.5] },
    { direction: WindDirection.S, range: [168.5, 191.5] },
    { direction: WindDirection.SSW, range: [191.5, 213.5] },
    { direction: WindDirection.SW, range: [213.5, 236.5] },
    { direction: WindDirection.WSW, range: [236.5, 258.5] },
    { direction: WindDirection.W, range: [258.5, 281.5] },
    { direction: WindDirection.WNW, range: [281.5, 303.5] },
    { direction: WindDirection.NW, range: [303.5, 326.5] },
    { direction: WindDirection.NNW, range: [326.5, 349.5] },
  ];

  const directionObj = directions.find(
    ({ range }) => windAngle >= range[0] && windAngle <= range[1]
  );
  return directionObj ? directionObj.direction : WindDirection.N;
};

export const getDirectionFromString = (windAngle) => {
  const angle = parseFloat(windAngle);
  return isNaN(angle) ? WindDirection.O : getDirectionFromAngle(angle);
};

export const WeatherDescription = {
  dayDescriptions: {
    d01: 'Sunny',
    d02: 'Hot day',
    d03: 'Partly cloudy',
    d04: 'Mostly cloudy',
    d05: 'Showers',
    d06: 'Cloudy, sleet',
    d07: 'Snow shower',
    d08: 'Overcast',
    d09: 'Light rain',
    d10: 'Heavy rain',
    d11: 'Snow',
    d12: 'Sleet',
    d13: 'Thundershowers',
    d14: 'Fog',
    d15: 'Fog',
  },
  nightDescriptions: {
    n01: 'Clear',
    n02: 'Clear and very hot',
    n03: 'Partly cloudy',
    n04: 'Mostly cloudy',
    n05: 'Showers',
    n06: 'Cloudy, sleet',
    n07: 'Snow shower',
    n08: 'Overcast',
    n09: 'Light rain',
    n10: 'Heavy rain',
    n11: 'Snow',
    n12: 'Sleet',
    n13: 'Thundershowers',
    n14: 'Fog',
    n15: 'Fog',
  },
  getWeatherDescription: (icon) => {
    if (icon.startsWith('d')) {
      return WeatherDescription.dayDescriptions[icon] || 'Unknown';
    } else if (icon.startsWith('n')) {
      return WeatherDescription.nightDescriptions[icon] || 'Unknown';
    } else {
      return 'Unknown';
    }
  },
};

export const WindDescription = {
  Calm: 'calm',
  LightAirs: 'light airs',
  LightBreeze: 'light breeze',
  GentleBreeze: 'gentle breeze',
  ModerateBreeze: 'moderate breeze',
  FreshBreeze: 'fresh breeze',
  StrongBreeze: 'strong breeze',
  Moderate: 'moderate',
  FreshGale: 'fresh gale',
  SevereGale: 'severe gale',
  Storm: 'storm',
  ViolentStorm: 'violent storm',
  Hurricane: 'hurricane',
};

export const getWindDescription = (windSpeed) => {
  const windSpeedInKMH = windSpeed * 3.6;
  if (windSpeedInKMH < 2) return WindDescription.Calm;
  if (windSpeedInKMH < 7) return WindDescription.LightAirs;
  if (windSpeedInKMH < 12) return WindDescription.LightBreeze;
  if (windSpeedInKMH < 20) return WindDescription.GentleBreeze;
  if (windSpeedInKMH < 31) return WindDescription.ModerateBreeze;
  if (windSpeedInKMH < 40) return WindDescription.FreshBreeze;
  if (windSpeedInKMH < 51) return WindDescription.StrongBreeze;
  if (windSpeedInKMH < 62) return WindDescription.Moderate;
  if (windSpeedInKMH < 75) return WindDescription.FreshGale;
  if (windSpeedInKMH < 89) return WindDescription.SevereGale;
  if (windSpeedInKMH < 103) return WindDescription.Storm;
  if (windSpeedInKMH < 118) return WindDescription.ViolentStorm;
  return WindDescription.Hurricane;
};

